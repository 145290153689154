import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"


const SliderIn = () => {
  function Img1() {
    return <StaticImage src="../images/cordyceps.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img2() {
    return <StaticImage src="../images/vitamin-c.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img3() {
    return <StaticImage src="../images/zinc.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img4() {
    return <StaticImage src="../images/lion-mane.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img5() {
    return <StaticImage src="../images/ginger.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img6() {
    return <StaticImage src="../images/hops.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img7() {
    return <StaticImage src="../images/ltheanine.png"
      alt=""
      placeholder="blurred"
      layout="fixed"
      width={200}
      height={200}
      formats={["auto", "webp", "avif"]}
       />
  }


  return (
    <>
      <Slider
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={false}
        arrows={false}
        dots={true}
        infinite={true}
        speed={2500}
        initialSlide={4}
        autoplay={true}
        responsive= {[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      ]}
      >
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img1()}
      <h3 style={{fontSize:'1.3em'}}>Kordyceps</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img2()}
      <h3 style={{fontSize:'1.3em'}}>Witamina C</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img3()}
      <h3 style={{fontSize:'1.3em'}}>Cynk</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img5()}
      <h3 style={{fontSize:'1.3em'}}>Imbir</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img4()}
      <h3 style={{fontSize:'1.3em'}}>Soplówka jeżowata</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img6()}
      <h3 style={{fontSize:'1.3em'}}>Chmiel</h3>
      </div></a>
      <a href="https://shop.shroom4you.com/pl/nasza-historia/#ingredients">
      <div className="mush">
      {Img7()}
      <h3 style={{fontSize:'1.3em'}}>L-Theanina</h3>
      </div></a>
      </Slider>
    </>
  );
};

export default SliderIn;
