import {React, useEffect} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../../components-pl/layout"

import SliderPhoto from '../../components-pl/slider'
import SliderIn from '../../components-pl/slider2'
import Seo from "../../components-pl/seo"
import Revs from "../../components-pl/reviews"
import SliderDrinks from '../../components-pl/slider-drinks'
import BlogModule from '../../components-pl/post-module'

const Home = ({ data, location }) => {



useEffect(() => {


  window.onscroll = function() {
    if(window.scrollY >= 45){
    document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
    document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
  }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
  document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
  }
  let mybtn = document.getElementById("back-to-top");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybtn.style.display = "block";
    } else {
      mybtn.style.display = "none";
    }
    };


})


  const siteTitle = data.site.siteMetadata.title
  //const energyDrinks = data.allWpProduct.edges
  const blogPosts = data.allWpPost.edges
  // console.log(blogPosts)
  return (
    <>
    <Layout data={data} location={location} title={siteTitle}>
      <Seo
      title="Shroom - naturalny napój energetyzujący z kordycepsem, soplówką jeżowatą i l'teaniną"
      description="Soplówka jeżowata, cordyceps i L-teanina to naturalne superfoods wspierające funkcje mózgu, podnoszące odporność i redukujące stres. W naszej ofercie znajdziesz szeroki wybór produktów: od naturalnych napojów energetycznych po odzież i akcesoria. Odkryj naszą gamę i zyskaj na zdrowiu i samopoczuciu."
      />
      <section className="hero is-white" style={{top:'80px',height:'955px'}}>
        <div id="hero" className="mask container-fluid" style={{zIndex:'1',top:'100px',position:'absolute',backgroundColor: 'rgba(0, 0, 0, 0.4)',display:'flex',alignItems:'center',flexWrap:'wrap',width:'100%',minWidth:'100%',height:'800px',justifyContent:'center'}}>
          <div className="col-8" style={{minHeight: '400px',display:'block',textAlign:'center'}}>
          <span className="spec-h"></span>
          <h1 className="text-white mob-hero spec-h" style={{textAlign:'center',fontSize:'5em',wordBreak:'break-word'}}>shroom drink</h1>
          <br />
          <h2 className="spec-h text-white" style={{textAlign:'center',fontSize:'1.2em'}}>Naturalny napój energetyzujący z kordycepsem, soplówką jeżowatą i l'teaniną</h2>
          <p className="text-white" style={{textAlign:'center',margin:'auto',maxWidth:'500px',color:'#111'}}>
          <br /><br />
          <br />
          <a href="#mushroom-drinks" className="btn btn-outline-light spec-h btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
            Poznaj Shroom Drink&nbsp;<span role="img" aria-label="mushroom">🍄</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/pl/" className="btn btn-outline-light spec-h btn-lg" style={{fontVariant:'small-caps',margin:'10px'}}>
            Sklep&nbsp;<span role="img" aria-label="shop">🛒</span>
          </a>
          </p>
          <br />
            <p className="spec-h text-white" >Dowiedz się więcej o naszych niesamowitych napojach!</p>
            <div id="scroll-to" style={{padding:'2em',textAlign:'center',color:'white'}}>
            <a href="#scroll-to" style={{fontSize:'1.5em'}}>⇩</a>
            </div>
          </div>
        </div>
        <video className="video-background" id="video" style={{top:'100px',position:'absolute',objectPosition:'center',objectFit:'cover',width:'100%',minWidth:'100%',height:'800px',zIndex:'0'}} autoPlay playsInline muted loop>
        <source src="https://admin.shroom4you.com/wp-content/uploads/2023/10/shroom-promo.mp4" type="video/mp4" />
        Twoja przeglądarka nie wspiera elementu video.
        </video>
    </section>

    <SliderPhoto />
    <br id="mushroom-drinks" />

    <br />
    <br />
    <section className="is-white">
    <div className="content has-text-centered" style={{padding:'30px',borderRadius:'0px'}}>
    <h2 className="title spec-h">Zobacz nasz sklep</h2>
    <p>Naturalne i zdrowe, innowacyjne, funkcjonalne napoje grzybowe, odzież i akcesoria.
    <br />
    </p>
    <br />
    <a href="https://shop.shroom4you.com/pl/#archive" style={{fontVariant:'small-caps'}} className="btn btn-outline-dark spec-h">
      SKLEP&nbsp;<span role="img" aria-label="mushroom">🍄</span>
    </a>
        <br /><br />
    </div>
    </section>
    <br /><br />
    <SliderDrinks />
    <br /><br />
    <div style={{marginTop:'50px',display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
      <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/pl/subskrypcje/">
        <div class="home-mod" style={{position: 'relative', maxWidth:'500px', overflow: 'hidden', padding: '1em', margin: '1em auto'}}>
        {/* Overlay container */}
        <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
          <h2 className="spec-h home-m text-white">Shroomskrypcje</h2>
          <p>Nigdy więcej nie zabraknie Ci napojów&nbsp;<span className="spec-h">shroom</span>. <br /><b>Pakiety subskrypcyjne!</b>
          </p>

        </div>

          <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/shroomscriptions.jpg"
            alt=""
            loading='lazy'
            style={{
               gridArea: "1/1",
               width:'100%',
               objectFit:'cover',
               minHeight:'300px',
               maxWidth: '100%',
               boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
               borderRadius:'25px',
             }}
             />
        </div>
      </a>
      <a target="_blank" rel="noreferrer" href="https://shop.shroom4you.com/pl/#ubrania-i-akcesoria">
      <div class="home-mod" style={{position: 'relative', maxWidth:'500px', overflow: 'hidden', padding: '1em', margin: '1em auto'}}>

      {/* Overlay container */}
      <div style={{ position: 'absolute', top: '20px', left: 'auto', padding: '1em', color: '#FEFEFE', zIndex: 2, width:'100%'}}>
        <h2 className="spec-h home-m  text-white">Akcesoria</h2>
        <p>Oryginalne akcesoria <span className="spec-h">shroom</span>. <br /> <b>Dla prawdziwych grzybiarzy i nie tylko!</b> </p>
      </div>

        <img src="https://admin.shroom4you.com/wp-content/uploads/2024/01/acc.jpg"
          alt=""
          loading="lazy"
          style={{
             gridArea: "1/1",
             width:'100%',
             objectFit:'cover',
             minHeight:'300px',
             maxWidth: '100%',
             boxShadow:'5px 5px 20px rgba(23,19,43,.2)',
             borderRadius:'25px',
           }}
           />
      </div>
      </a>
    </div>

    <div className="mt-5 container-fuild has-text-centered" style={{alignItems:'center',justifyContent:'center',textAlign:'center',margin:'5em 0em',padding:'2em 1em'}}>
    <h2 className="spec-h">
    Dowiedz się więcej o naszych składnikach!
    </h2>
    <p>
    Napoje grzybowe to prawdziwe superpożywienie, a raczej supernapoje! Sprawdź ich niesamowite składniki.
    </p>
    <br /><br />
    <SliderIn />
    </div>
    <br /><br />

    <Revs />
    <br /><br />
    <BlogModule />
    <br />
    <p style={{textAlign:'center'}}>
    <Link to="/pl/blog/" className="btn btn-outline-dark mush">Zobacz blog</Link>
    </p>
    <br /><br />
    </Layout>
    </>
  )
}

export default Home


export const pageQuery = graphql`
query GetEnergyDrinksPL {
  allWpProduct(filter: {productCategories: {nodes: {elemMatch: {slug: {eq: "napoje-grzybowe-i-roslinne"}}}}}) {
    edges {
      node {
        id
        name
        link
        image {
          id
          altText
          uri
          sizes
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
            }
          }
        }
        description
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
  allWpPost(sort: { fields: [date], order: DESC } limit: 7,filter: {language: {code: {eq: PL}}}) {
    edges {
      node {
        id
        date(formatString: "DD.MM.YYYY")
        link
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        excerpt
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        categories {
         nodes {
           name
           uri
         }
       }
       tags {
          nodes {
            name
          }
        }
      }
    }
  }
}
`
