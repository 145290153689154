import React from 'react'
import ReactStars from "react-rating-stars-component"
// import { Link } from "gatsby"
import Opinions from "../images/opinions.svg"
// import Favicon from '../images/favicon.png'
import { StaticImage } from "gatsby-plugin-image"

const Revs = () => {
  function Img1() {
    return <StaticImage src="../images/o1.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img2() {
    return <StaticImage src="../images/o2.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img3() {
    return <StaticImage src="../images/o3.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img4() {
    return <StaticImage src="../images/o4.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }
  return (
    <>
    <section id="reviews">
    <div className="container-fuild" style={{backgroundColor:'#E8D3D0',
    justifyContent:'center',textAlign:'center',margin:'0',padding:'2em 1em'}}>
    <br />
    <h2 className="title spec-h">Co ludzie mówią o naszych napojach?</h2>
    <br />
    <div className="row" style={{width:'100%',textAlign:'center',display:'inline-flex',flexWrap:'wrap',justifyContent:'center'}}>
      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img4()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>Jest to napój, którego można się napić i nie jest niezdrowy, a wręcz przeciwnie. Można pić coś innego niż wodę i trochę bardziej zaszaleć.</i></p>
      <p style={{fontSize:'1em'}}><b>Olek Rajewski</b>,<br /><sub>Artist</sub></p>
      <a style={{fontSize:'1em'}} href="https://www.instagram.com/oleksynolka/" target="_blank" rel="noopener noreferrer" className="text-dark">@oleksynolka</a>
      <br />
      </div>


      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img3()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>Jest coś naprawdę magicznego w tym napoju. Pomaga mi się odprężyć i uwolnić od otaczającego mnie chaosu. To jak mini urlop.</i></p>
      <p style={{fontSize:'1em'}}><b>PRIYANKA BANERJEE</b>,<br /><sub>CEO AND CO-FOUNDER</sub></p>
      {/*<a style={{fontSize:'1em'}} className="is-dark">Link</a>*/}
      DEI consulting
      <br /><br />
      </div>



      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img2()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>Jako przedsiębiorca prowadzący biznes i intensywne życie polecam Shrooma, bo pozwala mi się skupić i daje energię.</i></p>
      <p style={{fontSize:'1em'}}><b>Ewa Kucharska</b>,<br /><sub> CEO & founder Cluster Oficess</sub></p>
      <a style={{fontSize:'1em'}} href="https://www.clusteroffices.com/" target="_blank" rel="noopener noreferrer" className="text-dark">www.clusteroffices.com</a>
      <br />
      </div>



      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img1()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>Nasza randkowa noc z drinkami Shroom's była absolutnie fantastyczna! Napoje uczyniły nasz wieczór jeszcze bardziej wyjątkowym.</i></p>
      <p style={{fontSize:'1em'}}><b>Michaś i Karolina</b>,<br /><sub>Para</sub></p>
      <span style={{fontSize:'1em'}} className="is-dark">Studenci</span>
      <br />
      </div>
    </div>
    <br />
    <br />
    <a className="btn btn-dark mush" target="_blank"
    rel="noopener noreferrer"
    style={{color:'white'}}
    href="https://g.page/r/CSpTwe2GJoIuEAI/review">
    Dodaj opinię ⭐
    </a>
    </div>
    </section>

    </>
  );
};

export default Revs;
