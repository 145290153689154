import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"


const SliderDrinks = () => {

  const {
    allWpProduct,
  } = useStaticQuery(graphql`
    query sproPLQuery {
    allWpProduct(filter: {productCategories: {nodes: {elemMatch: {slug: {eq: "napoje-grzybowe-i-roslinne"}}}}}) {
      edges {
        node {
          name
          link
          currencyPrice
          ... on WpSimpleProduct {
            name
            featuredImage {
              node {
                altText
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            price
          }
        }
      }
    }
  }

  `)


  return (
    <>
      <Slider
        slidesToShow={2}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
        dots={true}
        infinite={true}
        speed={4000}
        initialSlide={2}
        autoplay={true}
        responsive= {[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      ]}
      >

      {allWpProduct.edges.map(post => {
        return (
        <>
        <div className="mush" style={{textAlign:'center'}}>
        <a aria-label={post.node.name} href={"https://shop.shroom4you.com"+post.node.link} target="_blank" rel="noopener noreferrer">
        <GatsbyImage
          image={post.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          alt={post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.name}
          style={{ marginBottom: 15 }}
          imgStyle={{width:'100%'}}
          loading="lazy"
        />
        <h3 className="spec-h" style={{fontSize:'1.2em',maxWidth:'240px',margin:'auto'}}>{post.node.name}</h3>
        </a>
        </div>
        </>
      )
      })}
      </Slider>
    </>
  );
};

export default SliderDrinks;
